'use strict';

const angular = require('angular');

// Define a module
const app = angular.module('vub-is.accom.userarea.application', [
  require('web-client/src/core'),
]);

app.controller('ApplicationFormController', require('./ApplicationFormController.js'));
app.controller('ApplicationConfirmController', require('./ApplicationConfirmController.js'));
app.controller('ApplicationListController', require('./ApplicationListController.js'));
app.controller('ApplicationAgreementController', require('./ApplicationAgreementController.js'));


// Setup the states
app.config(($stateProvider, authProvider) => {
  const authenticate = authProvider.authenticate();
  $stateProvider.state('application', {
    template: '<ui-view></ui-view>',
  });
  $stateProvider.state('application.create', {
    url: '/u/application/create/:type',
    params: {
      type: 'queue',
    },
    controller: 'ApplicationFormController as vm',
    template: require('./templates/form.html'),
    resolve: {
      campuses: campusService => campusService.load(),
      faculties: facultyService => facultyService.load(),
      settings: settingsService => settingsService.load(),
    },
  });
  $stateProvider.state('application.success', {
    template: require('./templates/form_success.html'),
  });
  $stateProvider.state('application.edit', {
    url: '/u/application/:id/edit/',
    controller: 'ApplicationFormController as vm',
    template: require('./templates/form.html'),
    resolve: {
      user: authenticate,
      campuses: campusService => campusService.load(),
      faculties: facultyService => facultyService.load(),
      settings: settingsService => settingsService.load(),
    },
  });
  $stateProvider.state('application.confirm', {
    url: '/u/application/:id/:loginToken',
    controller: 'ApplicationConfirmController as vm',
    template: require('./templates/message.html'),
  });
  $stateProvider.state('application.list', {
    url: '/u/application/list',
    controller: 'ApplicationListController as vm',
    template: require('./templates/list.html'),
    resolve: {
      user: authenticate,
      applications: applicationService => applicationService.find(),
    },
  });

  $stateProvider.state('application.agreement', {
    url: '/u/application/:id/agreement/',
    controller: 'ApplicationAgreementController as vm',
    template: require('./templates/agreement.html'),
    resolve: {
      user: authenticate,
      campuses: campusService => campusService.load(),
      faculties: facultyService => facultyService.load(),
      settings: settingsService => settingsService.load(),
    },
  });
});

module.exports = app.name;
