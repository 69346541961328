'use strict';

const _ = require('lodash');

module.exports =
/* @ngInject */
function ($state, $stateParams, auth, applicationService, t, error) {

  const vm = this;

  auth.loginWithToken($stateParams.loginToken)
    .then(() => applicationService.confirm($stateParams.id))
    .then(() => $state.go('application.list'))
    .catch((err) => {
      // TODO: Log an error message
      console.error(err);
      $state.go('application.list');
    });

}
