'use strict';

// Ensure jQuery is globally available
global.jQuery = global.$ = require('jquery/dist/jquery.js');

const angular = require('angular');

// Global styles
require('./styles/main.scss');

// Require Semantic UI component scripts
require('semantic-ui-css/components/transition.js');
require('semantic-ui-css/components/dropdown.js');
require('semantic-ui-css/components/popup.js');

// Initialize our app
const app = angular.module('vub-is.accom', [
  require('angular-sanitize'),
  require('angular-ui-router'),
  require('angular-vs-repeat'),
  require('ng-file-upload'),
  require('web-client/src/auth'),
  require('web-client/src/core'),
  require('web-client/src/i18n'),
  require('./userarea'),
]);

app.controller('MenuController', require('./MenuController.js'));

// Cache the menu.html page
app.run($templateCache => {
  $templateCache.put('menu.html', require('./menu.html'))
});

// HTML5 router mode
app.config($locationProvider => {
  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false,
  });
});

// Setup ui-router
app.config($urlRouterProvider => {
  // Treat urls with trailing slashes as any other url
  $urlRouterProvider.rule((_, $location) => {
    const path = $location.path();
    const hasTrailingSlash = path[path.length - 1] === '/';
    if (hasTrailingSlash) {
      // If last charcter is a slash, return the same url without the slash
      return path.substr(0, path.length - 1);
    }
  });
  // Any unmatched url
  $urlRouterProvider.otherwise('/u/');
});

// Setup $http service
app.config($httpProvider => {
  $httpProvider.useApplyAsync(true);
  $httpProvider.useLegacyPromiseExtensions(false);
});

// Register JWT auth middleware
app.config(($httpProvider, jwtOptionsProvider) => {
  $httpProvider.interceptors.push('jwtInterceptor');
  jwtOptionsProvider.config({
    tokenGetter: ['auth', auth => auth.getToken()],
    whiteListedDomains: [
      'registracija.vub.lt',
      'localhost',
    ],
  });
});

// Register i18n middleware to translate API services
app.config($httpProvider => {
  $httpProvider.interceptors.push('i18nInterceptor');
});

// Modify $state to include data about next route
app.config($provide => {
  $provide.decorator('$state', ($delegate, $rootScope) => {
    $rootScope.$on('$stateChangeStart', (e, state) => {
      $delegate.next = state;
    });
    return $delegate;
  });
});

app.constant('API_BASE', window.API_BASE || '/api/v1');

// API service
app.factory('$httpApi', ($http, API_BASE) => {
  const makeRequestFn = $http => method => (url, ...rest) => {
    return $http[method](API_BASE + url, ...rest);
  };
  return {
    get: makeRequestFn($http)('get'),
    head: makeRequestFn($http)('head'),
    post: makeRequestFn($http)('post'),
    put: makeRequestFn($http)('put'),
    delete: makeRequestFn($http)('delete'),
  };
});

// Disable debug info for better performance
if (global.NODE_ENV === 'production') {
  app.config($compileProvider => {
    $compileProvider.debugInfoEnabled(false);
  });
}

// Broadcast escape key events
app.run(($document, $rootScope) => {
  $document.bind('keydown', (e) => {
    if (e.keyCode === 27) {
      $rootScope.$broadcast('keypress_esc', e);
    }
  });
});

// Reload state when logging out
app.run(($state, auth) => {
  auth.on('logout', () => $state.reload());
});

module.exports = app.name;
