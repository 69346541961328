'use strict';

module.exports =
/* @ngInject */
function (auth, i18n, $httpApi) {

  const vm = this;

  vm.sendSupportMail = function () {
    $httpApi
      .post('/mail/support', {
        name: vm.name,
        email: vm.email,
        text: vm.text,
        captcha: vm.captcha,
      })
      .then(() => {
        vm.sendSuccess = true;
      })
      .catch(vm.form.$errorHandler('support.contact_form.error'));
  };

};
