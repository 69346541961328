'use strict';

const angular = require('angular');

const app = angular.module('vub-is.accom.agreement', [
]);

app.controller('ApplicationAgreementController', require('../application/ApplicationAgreementController.js'));


// Setup the states
app.config(($stateProvider, authProvider) => {
    $stateProvider.state('agreement', {
        url: '/sutarties-pratesimas',
        template: require('../application/templates/agreement.html'),
        params: {
            forYear: (new Date).getFullYear(),
        },
        controller: 'ApplicationAgreementController as vm',
        resolve: {
            settings: (settingsService) => settingsService.load(),
        },
    });
});

module.exports = app.name;
