'use strict';

const angular = require('angular');

const app = angular.module('vub-is.accom.userarea', [
  require('./application'),
  require('./agreement'),
]);

app.controller('ContactController', require('./ContactController.js'));

// Setup the states
app.config(($stateProvider, authProvider) => {
  $stateProvider.state('index', {
    url: '/u/',
    template: require('./index.html'),
    controller: function (settings) {
      this.settings = settings.getAll();
    },
    controllerAs: 'vm',
    resolve: {
      settings: (settingsService) => settingsService.load(),
    },
  });
});

module.exports = app.name;
