'use strict';

const _ = require('lodash');
const print = require('print-js');

module.exports =
  /* @ngInject */
  function ($scope, $state, $stateParams, auth, applicationService, campusService) {

    const vm = this;

    vm.agreement = {};
    vm.application = {};
    vm.period = ((year) => {
      const months = [
        'sausio',
        'vasario',
        'kovo',
        'balandžio',
        'gegužės',
        'birželio',
        'liepos',
        'rugpjūčio',
        'rugsėjo',
        'spalio',
        'lapkričio',
        'gruodžio',
      ];
      return year
        ? {
          from: `${year} m. ${months[7]} mėn. 1 d.`,
          to: `${year + 1} m. ${months[6]} mėn. 31 d.`,
        }
        : {
          from: '20____ m. _______________________ mėn. ___ d.',
          to: '20____ m. _______________________ mėn. ___ d.',
        };
    })($stateParams.forYear);
    // TODO load from service
    vm.campuses = {
      '1': 'Saulėtekio al. 4',
      '2': 'Saulėtekio al. 6',
      '3': 'Saulėtekio al. 8',
      '4': 'Saulėtekio al. 12',
      '5': 'Saulėtekio al. 29',
      '6': 'Saulėtekio al. 31',
      '7': 'Saulėtekio al. 33',
      '8': 'Saulėtekio al. 35',
      '9': 'Saulėtekio al. 41',
      '10': 'Saulėtekio al. 47',
      '11': 'Saulėtekio al. 49',
      '12': 'Saulėtekio al. 51',
      '13': 'Didlaukio g. 59',
      '14': 'M.K. Čiurlionio g. 23',
      '15': 'M.K. Čiurlionio g. 25',
      '16': 'M.K. Čiurlionio g. 27',
    };

    vm.rooms = {
      'Penkiasdešimt Eurų 0 ct. (50,00)': 'Dvivietis (50 Eurų)', 
      'Vienas šimtas Eurų 0 ct. (100,00)':  'Visas dvivietis (100 Eurų)', 
      'Keturiasdešimt keturi Eurai 0 ct. (44,00)': 'Trivietis (44 Eurai)',
      'Šešiasdešimt penki Eurai  0ct. (65,00)': 'Trivietis (2 asmenims) (65 Eurai)',
      'Vienas šimtas trisdešimt Eurų 0 ct. (130,00)': 'Visas trivietis (130 Eurų)', 
    };

    vm.roomAgreementSection = {
      'Penkiasdešimt Eurų 0 ct. (50,00)': '11.3', 
      'Vienas šimtas Eurų 0 ct. (100,00)': '11.3', 
      'Keturiasdešimt keturi Eurai 0 ct. (44,00)': '11.2',
      'Šešiasdešimt penki Eurai  0ct. (65,00)': '11.2',
      'Vienas šimtas trisdešimt Eurų 0 ct. (130,00)': '11.2', 
    };

    applicationService
      .get($stateParams.id)
      .then(item => {
        Object.assign(vm.application, item);
      });

    vm.submit = async () => {
      // Compile a template
      const agreementTemplate = require('./templates/print_agreement.html');
      const agreementHtml = _.template(agreementTemplate, {
        interpolate: /{{([\s\S]+?)}}/g,
      })({
        agreement: vm.agreement,
        application: vm.application,
        user: await auth.getUser().catch(error => null),
        period: vm.period,
        campus: vm.agreement.campus
          ? vm.campuses[vm.agreement.campus] 
          : '_______________________',
        roomNumber: vm.agreement.roomNumber ||'___________',
        section_11_2: (vm.agreement.roomPrice && vm.roomAgreementSection[vm.agreement.roomPrice] == '11.2')
          ? vm.agreement.roomPrice
          : '______________________________________________________________',
          section_11_3: (vm.agreement.roomPrice && vm.roomAgreementSection[vm.agreement.roomPrice] == '11.3')
          ? vm.agreement.roomPrice
          : '______________________________________________________________',
      });
      print({
        printable: agreementHtml,
        type: 'raw-html',
      });
    };

  }
