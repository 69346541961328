'use strict';

const _ = require('lodash');

module.exports =
  /* @ngInject */
  function ($scope, $state, $stateParams, $q, $httpApi, Upload, API_BASE, auth,
    countries, campuses, faculties, applicationService, settings, t) {

    const vm = this;

    vm.API_BASE = API_BASE;

    const PERSONAL_CODE_ERRORS = [
      'user.invalid_personal_code',
      'application.personal_code_not_in_vu_database',
    ];

    const CAMPUS_ERRORS = [
      'application.campus_not_allowed',
    ];

    function beforeRequest() {
      vm.loading = true;
    }

    function afterRequest() {
      vm.loading = false;
    }

    vm.application = {
      type: $stateParams.type || 'queue',
      country: $stateParams.type === 'international'
        ? ''
        : 'LT',
      roomType: 'any',
    };

    vm.studyTypes = {
      full: t('study_type.full'),
      exchange: t('study_type.exchange'),
    };

    vm.campuses = campuses;
    vm.faculties = faculties;
    vm.countries = countries;
    vm.roomTypes = applicationService.roomTypes;
    vm.studyCycles = applicationService.studyCycles;
    vm.sexes = {
      male: t('sex.male'),
      female: t('sex.female'),
    };

    if ($stateParams.id) {
      vm.update = true;
      vm.loggedIn = true;
      applicationService.get($stateParams.id).then((item) => {
        Object.assign(vm.application, item);
        vm.uploaded_files = item.getFiles();
      });
    }
    else {
      auth.getUser().then((user) => {
        vm.loggedIn = true;
        Object.assign(vm.application, user.export());
      });
    }

    $scope.$watch('vm.application.campus', () => {
      if (!vm.application.campus) {
        return;
      }
      if (!vm.application.campus.roomTypeEnabled) {
        vm.application.roomType = 'any';
      }
    });

    vm.submit = function (item) {
      let promise = $q.when();

      const hasUploadableFiles = vm.files && vm.files.length > 0;
      const hasExistingFiles = item.files && item.files.length > 0;
      const hasFiles = hasUploadableFiles || hasExistingFiles;
      if (item.disadvantaged && !hasFiles) {
        vm.file_error = true;
        return null;
      }

      if (!$stateParams.id && !vm.areTermsAccepted) {
        return vm.form.$setFieldError('tos_checkbox', 'error.application.tos_checkbox');
      }

      if (item.type === 'freshmen') {
        const allowed = item.studyCycle == '1' && settings.get('enable_freshmen_cycle_1')
          || item.studyCycle == '2' && settings.get('enable_freshmen_cycle_2')
          || item.studyCycle == '3' && settings.get('enable_freshmen_cycle_3')
          || item.studyCycle == '4' && settings.get('enable_freshmen_cycle_4');
        if (!allowed) {
          return vm.form.$setFieldError('study_cycle',
            'error.application.freshmen_cycle_disabled');
        }
      } else if (item.type === 'international') {
        const allowed = settings.get('enable_international')
        if (!allowed) {
          return vm.form.$setFieldError('study_cycle',
            'error.application.international_queue_disabled');
        }
      } else {
        const allowed = vm.application.campus
          && !vm.application.campus.hasStudyCycleMask()
          || vm.isStudyCycleConfirmed;
        if (!allowed && !vm.update) {
          return vm.form.$setFieldError('study_cycle_checkbox',
            'error.application.study_cycle_not_accepted');
        }
      }

      beforeRequest();

      // Try to upload files
      if (hasUploadableFiles) {
        promise = promise
          .then(() => Upload.upload({
            url: API_BASE + '/upload',
            arrayKey: '',
            data: {
              files: vm.files,
            },
          }))
          .then((res) => {
            if (!res.data.files) {
              $q.reject();
            }
            // Append or create new "files" array
            item.files = item.files
              ? item.files.concat(res.data.files)
              : res.data.files;
            vm.files = void 0;
          });
      }

      if (!$stateParams.id) {
        // Submit new
        promise = promise
          .then(() => applicationService.submit(item, vm.captcha))
          .then(() => {
            if (vm.loggedIn) {
              $state.go('application.list');
            } else {
              $state.go('application.success');
            }
          });
      } else {
        // Update existing
        promise = promise
          .then(() => applicationService.update(item))
          .then(() => $state.go('application.list'));
      }

      return promise
        .catch((err) => {
          $httpApi.post('/log', {
            application: vm.application,
            $stateParams,
            err,
            userAgent: navigator.userAgent,
          });
          if (err.status === 413) {
            vm.file_error = 'size';
            return null;
          }
          if (err.data && PERSONAL_CODE_ERRORS.includes(err.data.code)) {
            vm.form.$setFieldError('personal_code', 'error.' + err.data.code);
            return null;
          }
          if (err.data && CAMPUS_ERRORS.includes(err.data.code)) {
            vm.form.$setFieldError('campus', 'error.' + err.data.code);
            return null;
          }
          throw err;
        })
        .catch(vm.form.$errorHandler('userarea.application.submit_error'))
        .finally(() => afterRequest());
    };

  }
