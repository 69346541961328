'use strict';

const _ = require('lodash');

module.exports =
/* @ngInject */
function ($state, applicationService, applications, t, error) {

  const vm = this;

  vm.applications = applications;

  vm.edit = function (item) {
    $state.go('application.edit', { id: item.id });
  };

  vm.cancel = function (item) {
    const result = window.confirm(t('application.cancel_dialog'));
    if (result) {
      applicationService.cancel(item);
    }
  };

  vm.accept = function (item) {
    applicationService.accept(item)
      .catch(error.handler(vm));
  };

  vm.sendConfirmationToken = function (item) {
    applicationService.sendConfirmationToken(item)
      .then(() => $state.go('application.success'))
      .catch(error.handler(vm));
  };

  vm.print = function (item) {
    $state.go('application.agreement', {id: item.id});
  }

}
